import { Button, Card, CardActions, CardContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
    content__body: {
        backgroundColor: '#F4F7FC'
    },
  root: {
    minWidth: '100%',
    textAlign: 'center',
    minHeight: '180px;',
  },
  title: {
    fontSize: 18,
  },
  viewBtn: {
    marginBottom: 5,
    backgroundColor: '#00BCD4',
    color: '#fff',
    padding: '5px 15px',
    borderRadius: '15px',
    '&:hover':{
        color: 'white',
        backgroundColor: 'orange'
    }
  },
  viewBtn__action: {
    justifyContent: 'center',
  }
});



const LeadForms = () => {

    const classes = useStyles();
    const [formLead, setFormLead] = useState([]);

    const corsURL = 'https://cors-anywhere.herokuapp.com/';
    const token = 'EAALs0t78nQABACCjoYRz90DDKhqUQovODDB4o7ZCeNZCcjqP4FE7MbZCBTCG1sFN1zTzoFUnumAbTOUuiP3J4vs2SQHZBW3FyTt5z7iYYNwVFgg71pE1NyGCwrUYZCVL52JmD13bhXuj9fFLgddEwzscpNZA73L8FuRZAEgmFxZAAtyZBoUNbCCIYCAQvvxGfxWgZD';


    const formURL = `https://graph.facebook.com/v8.0/146389142166991?fields=leadgen_forms{leads_count,name,created_time,status,locale}&access_token=${token}`;

    useEffect(()=>{

        fetch(formURL)
        .then(res => res.json())
        .then(result => {
            setFormLead(result.leadgen_forms.data);
        })
        
    },[]);

    console.log(formLead);

    // Click Event

    let history = useHistory();

    const goLeadDetails = (id) => {
        history.push(`leadsdetails/${id}`);
        console.log("getLeadId>>>", id);
    }

    return (
        <Grid container spacing={3} className={classes.content__body}>
            {
                formLead.map( getLead => 
                    
                    <Grid item xs={3} key={getLead.id}>
                        <Card className={classes.root}>
                            <CardContent>
                                <Typography variant="h5" component="h5" className={classes.title}>
                                    {getLead.name}
                                </Typography>
                                <Typography className={classes.pos} color="textSecondary">
                                Total Leads: {getLead.leads_count}
                                </Typography>
                                <Typography className={classes.pos} color="textSecondary">
                                Status: {getLead.status}
                                </Typography>
                               {/*  <Typography className={classes.pos} color="textSecondary">
                                Created: {getLead.created_time.toString()}
                                </Typography> */}
                                
                            </CardContent>
                            <CardActions className={classes.viewBtn__action}>
                                <Button size="small" onClick={() => goLeadDetails(getLead.id)} className={classes.viewBtn}>View Detail</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    )
            }
        </Grid>
    );
};

export default LeadForms;