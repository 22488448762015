// import Button from '@material-ui/core/Button';
import { Box, Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import LeadForms from '../LeadForms/LeadForms';
import Sidebar from '../Sidebar/Sidebar';
const Home = () => {
    
    return (
            <Grid container>
                <Grid item xs={2} >
                    <Sidebar></Sidebar>
                </Grid>
                <Grid item xs={10}>
                    <Box component="span">
                        <Typography variant="h3" style={{fontSize: '18px', padding: '30px'}}>Facebook Lead View</Typography>
                    </Box>
                    <Container>
                        <LeadForms></LeadForms>
                    </Container>
                </Grid>
            </Grid>
    );
};

export default Home;