import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./Components/Home/Home";
import LeadsTable from "./Components/LeadsTable/LeadsTable";

export const ACCESS_TOKEN ="EAALs0t78nQABACCjoYRz90DDKhqUQovODDB4o7ZCeNZCcjqP4FE7MbZCBTCG1sFN1zTzoFUnumAbTOUuiP3J4vs2SQHZBW3FyTt5z7iYYNwVFgg71pE1NyGCwrUYZCVL52JmD13bhXuj9fFLgddEwzscpNZA73L8FuRZAEgmFxZAAtyZBoUNbCCIYCAQvvxGfxWgZD";

export const CORS_URL = "https://cors-anywhere.herokuapp.com";
export const FB_URL = "https://graph.facebook.com/v8.0";
export const PAGE_ID = 146389142166991;

function App() {
  return (
    <div className="main-wrapper">
      <Router>
          <Switch>
            <Route exact path="/">
              <Home></Home>
            </Route>
            <Route path="/leadsdetails/:leadId">
              <LeadsTable></LeadsTable>
            </Route>
          </Switch>
      </Router>
    </div>
  );
}

export default App;
