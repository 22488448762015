import { Box, Button } from '@material-ui/core';
import React from 'react';

const Sidebar = () => {
    return (
        <>
            <Box component='div' style={{padding: '30px'}}>
                <img src={process.env.PUBLIC_URL + '/boost-logo.png'} alt="" width="180" />
                <Button color="inherit">Dashboard</Button>
            </Box>
        </>
    );
};

export default Sidebar;