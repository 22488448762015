import { Box, Button, Card, CardContent, Container, Grid } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { Link, useParams } from 'react-router-dom';
import { ACCESS_TOKEN, FB_URL } from '../../App';
import Sidebar from '../Sidebar/Sidebar';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const LeadsTable = () => {
    const classes = useStyles();
    let { leadId } = useParams();


    const [leads, setLeads] = useState([]);

    const leadsURL = `${FB_URL}/${leadId}?fields=leads&access_token=${ACCESS_TOKEN}`;

    useEffect(()=>{
        fetch(leadsURL)
        .then(res => res.json())
        .then(result => {
            setLeads(result.leads);
        })
    },[]);

    console.log("LEADS>>>",leads);
    // console.log("LEADS-PAGING>>>",leads.paging);

    

    const leadNext = (nextLead) => {
        fetch(nextLead)
        .then(res => res.json())
        .then(result => {
            setLeads(result);
            console.log(result);
        })
    }

    
    const leadPrevious = (previousLead) => {
        fetch(previousLead)
        .then(res => res.json())
        .then(result => {
            setLeads(result);
            console.log(result);
        })
    }

    const today = new Date();
    const dateISO = today.toISOString().split('T')[0];
    console.log("today>>>>", dateISO);


    return (
        <Grid container>
            <Grid item xs={2}>
                <Sidebar></Sidebar>
            </Grid>
            <Grid item xs={10}>
                <Box component="span">
                    <Typography variant="h3" style={{fontSize: '18px', padding: '30px'}}>Facebook Lead View</Typography>
                </Box>
                <Container>
                  <Box component="span" style={{float: 'right', marginBottom: '30px'}}>
                    {
                      leads.paging &&
                      leads.paging.previous &&

                      <Button style={{marginRight: '10px'}} variant="contained" color="primary" onClick={() => leadPrevious(leads.paging.previous)}>Previous</Button>
                    }
                    
                    {
                      leads.paging &&
                      <Button variant="contained" color="primary" onClick={() => leadNext(leads.paging.next)}>Next</Button>
                    }
                  </Box>

                
                  <Grid container spacing={2}>
                  {
                    leads.data &&
                    leads.data.map( lead => 
                      <Grid item xs={4} key={lead.id}>
                        <Card>
                          <CardContent>
                            <Typography variant="h6" className="dateTime">
                              
                              {

                                lead.created_time.split('T')[0] === dateISO ? 'Today' : <Moment>{lead.created_time}</Moment>
                                
                              }
                            </Typography>
                            {
                              lead.field_data.map(leadValue => 
                              <li key={leadValue.email} className="leadValue" style={{listStyle: 'none'}}>
                                <DoubleArrowIcon style={{fontSize: '12px', color: '#3f51b5'}} /> {leadValue.values[0].charAt(0).toUpperCase() + leadValue.values[0].slice(1).split('_').join(' ')
                                }
                              </li>)
                            }
                          </CardContent>
                        </Card>
                      </Grid>
                      
                      )
                  }
                  </Grid>
                </Container>
            </Grid>
        </Grid>
    );
};

export default LeadsTable;